.keyboardEditDocumentTextInline table {
    border-spacing: 0;
    border-collapse: collapse;
    padding: 5px;
    display: inline-block;
}

.keyboardEditDocumentTextInline td,th {
    text-align: center;
    border-spacing: 0;
    border: 1px solid #e7e7e7;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: content-box;
}

.keyboardEditDocumentTextInline > div > pre {
    width: 100%;
}

.keyboardEditDocumentTextInline  pre {
    margin: 0 !important;
    padding: 10px !important;
    font-size: 15px !important;
    font-family: apparat;
}


.keyboardEditDocumentTextInline p {
    display: inline;
    margin-block-start: 0;
    margin-block-end: 0;
}

.keyboardEditDocumentTextInline ul {
    white-space: normal;
}

.keyboardEditDocumentText ol {
    white-space: normal;
    margin-block-start: 5px;
    margin-inline-start: 5px;
}

.keyboardEditDocumentText ul {
    white-space: normal;
    margin-block-start: 5px;
    margin-inline-start: 5px;
}

.keyboardEditDocumentText .olSingle {
    margin-block-start: 0px;
}

.keyboardEditDocumentText .fbox {
    border: none !important;
}

h1, h2, h3, h4 {
    font-size: 16px;
    font-weight: 500;
    margin-block-start: 5px;

    color: #efefef;
}


.benxtAppDesktop h1, h2 {
   font-size: 14px;
}

.benxtAppDesktop h3, h4 {
    font-size: 14px;
}

strong {
    font-weight: 500;
    color: #efefef;
}

.keyboardEditDocumentTextInline ::marker {

}

.keyboardEditDocumentTextInline .inlinePara {
    display: inline-block;
}

.keyboardEditDocumentTextInline blockquote {
    margin-block-start: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.liFun marker {
}

ul,ol .liContent {
    vertical-align: top;
    white-space: normal;

}

h1 + ul, h1 + ol,
h2 + ul, h2 + ol,
h3 + ul, h3 + ol,
h4 + ul, h4 + ol,
h5 + ul, h5 + ol,
h6 + ul, h6 + ol {
    margin-block-start: 0 !important;
}

li + li {
    margin-top: 15px;
}

ol + .katex-display, ul + .katex-display {

}

.katex-display + .katex-display {
    margin-top: 5px;
}

.liFun {
}

.para {
    display: block;
}

.markdownContainer p {
    margin-block-start: 0 !important;
}

.markdownContainer p:last-child {
    margin-block-end: 0 !important;
}

.markdownContainer {
    white-space: normal;
}
