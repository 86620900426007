.llmaoApp {
    height: calc(100% - 90px);
    margin-top: 90px;
}

.marvin {
    font-family: marvin, sans-serif;
    font-style: normal;
    font-weight: 400;
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header h1 {
  margin-right: 20px;
}

.joke-topic-container {
    position: relative;
    z-index: 1;
}

.joke-topic {
    background: rgb(114, 12, 19);
    padding: 15px;
    clip-path: polygon(0 0, 100% 3px, calc(100% - 5px) 100%, 3px 100%);
}


.jokeTopicLabel {
    position: absolute;
    z-index: 1;
    top: calc(-35px + 12px);
    left: 15px;
    font-size: 20px;
    font-weight: bold;
}

.jokeTopicLabel .outlineInner {
    color: rgb(114, 12, 19);
}

.joke-topic label {
  display: block;
  font-weight: bold;
}

.joke-topic input {
  width: 100%;
  padding: 5px;
  font-size: 16px;
}


.main-content {
    position: relative;
    margin-top: 20px;
    width: 100%;
    width: 100%;
}

.joke-button {
  background-color: rgb(11, 132, 0);
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: center;
  clip-path: polygon(0 0, 100% 3px, calc(100% - 5px) 100%, 3px 100%);
}

.joke-display {
  margin-top: 10px;
  font-size: 18px;
  color: white;
  left: 0;
  position: relative;
  min-height: 80px;
  width: 100%;
}

.joke-display-container {
    position: relative;
    filter: drop-shadow(4px 4px 0px #202d44);
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
}

.last-joke-container {
    left: 0;
    z-index: 10;
}

.llmaoAppDesktop .last-joke-container {
  max-width: calc(min(600px, 100vw));
}

.joke-item-shadow-container {
    width: 100%;
    transform: rotateZ(360deg);
}

.category-button-display-container {
  flex-grow: 1;
  filter: drop-shadow(4px 4px 0px #202d44);
}

.plain-button-display-container {
    transform: rotateZ(360deg);
    filter: drop-shadow(4px 4px 0px #202d44);
}

.plain-button-selectable {
    cursor: pointer;
}

.joke-display-container .plain-button-display-container {
    filter: none;
}

.plain-button-display-container .outlineText {
    font-size: 20px;
    -webkit-text-stroke: 1px white;
    transform: translate(0, -20px);
}

.llmaoAppMobile .category-button-display-container .outlineText {
    font-size: 20px;
}

.llmaoAppMobile .plain-button-display-container .outlineText {

}

.trapezoid {
  padding-left: 20px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
}

.trapezoid.orange {
    background-color: rgb(201, 75, 36);
    font-size: 22px;
    padding-bottom: 25px;
}

.trapezoid.blue.withMarkdown {
    padding-top: 25px;
    padding-bottom: 40px;
    transform: translate(10px, 0);
    width: calc(100% - 0px);
}

.joke-item-topic {
    display: flex;
    width: 100%;
    font-size: 22px;
    transform: translate(15px, 15px);
}

.joke-item-topic-content {
    background-color: rgb(0, 113, 188);
    color: #e7e7e7;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.trapezoid.blue {
    margin-bottom: 50px;
    width: 100%;
}

.jokeContent {
    background-color: rgb(0, 77, 119);
    justify-content: flex-start;
    padding: 20px;
    font-size: 22px;
    width: calc(100% - 50px);
    min-height: 60px;
    max-height: 40vh;
    overflow-y: auto;
    margin-left: 10px;
    padding-bottom: 40px;
}

.joke-categories {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 4px;
  padding-right: 15px;
  max-width: 100%;
}

.llmaoAppMobile .joke-categories {
    margin-top: 20px;
    margin-bottom: 0px;
}

.llmaoAppMobile .category-button .outlineText {
    font-size: 16px;
}

.category-button {
  background-color: rgb(102, 45, 145);
  color: white;
  padding: 10px;
  border: none;
  font-size: 16px;
  padding-left: 25px;
  padding-right: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}


.joke-list {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
    transform: translate(0, -10px);
    padding-right: 25px;
    padding-left: 0px;
    width: calc(100% - 25px);
    overflow-y: auto;
    height: 100%;
}

.joke-list-filler {
    width: 100%;
    min-height: 25px;
}

.joke-item {
  color: white;
  position: relative;
  min-height: 50px;
}

.joke-item-container {
    width: 100%;
    position: relative;
}


.inputControlInputEditorInput {
    font-size: 16px;
    white-space: pre-wrap;
    max-height: 164px;
    overflow-y: auto;
    overflow-x: hidden;
    font-weight: normal;
    /*
    left: 10px;
    min-height: 18px;
    width: calc(100% - 55px);
    bottom: 8px;
    width: calc(100% - 45px);
    font-size: 16px;
    left: 0px;
    pointer-events: auto;
    min-height: 18px;
   */
    min-height: 20.5px;
    left: 20px;
    width: calc(100% - 55px);
    font-size: 16px;
    color: black;
    position: absolute;
    z-index: 2;
    bottom: 10px;
}

.inputControlEditorPlaceholder {
    font-size: 16px;
    transform: translate(20px, 10px);
    max-width: calc(100% - 20px);
    overflow: hidden;
    color: #777777;
    pointer-events: none;
    white-space: nowrap;
    z-index: 2;
}

.inputControlInputEditorBg {
    position: absolute;
    left: 0px;
    bottom: 0px;
    background: white;
    width: 100%;
    min-height: 40px;
}

.inputControlInputEditor {
    width: 100%;
    position: relative;
    min-height: 40px;
}

.inputControlEditorCross {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    z-index: 10;
}

.inputControlEditorCross div {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputControlEditorCross svg {
    height: 20px;
    width: 20px;
    fill: white  !important; 
}

.inputControlEditorFocused .inputControlEditorCross svg {
    fill: white !important; 
}

.inputControlInputEditorInput:focus {
    outline: none;
}

.inputControlEditorDownward .inputControlInputEditorInput {
    bottom: auto;
    top: 10px;
}

.inputControlEditorDownward .inputControlInputEditorBg {
    bottom: auto;
    top: 0px;
}

.top-laughs {
    background: rgb(153, 153, 153);
}

.joke-item-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;
    margin-right: 20px;
    column-gap: 0px;
    position: absolute;
    width: 100%;
    bottom: -30px;
}

.joke-item-no-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;
    margin-right: 20px;
    position: absolute;
    column-gap: 0px;
    width: calc(100% - 7px);
    bottom: -30px;
    padding-right: 7px;
}


@keyframes shake {
  0%, 100% {
    transform: translate(0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate(-4px, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate(4px, 0);
  }
}

.shake {
    animation: shake 1.0s infinite;
}

.liked {
    color: rgb(18, 163, 29);
}

.unliked {
}

.outlineText {
    position: relative;
    font-family: marvin, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.category-button .outlineText {
    font-size: 25px;
    color: rgb(153, 153, 153);
}

.outlineInner {
    position: absolute;
}

.outlineOuter {
    color: white;
    -webkit-text-stroke: 10px white;
}

.llmaoAppMobile .outlineOuter {
    -webkit-text-stroke: 7px white;
}

.jokeTopicLabel .outlineOuter {
    -webkit-text-stroke: 8px white;
}

.tryAgainButtons.tryAgainButtonsOrig {

}

.tryAgainButtons.tryAgainButtonsNew {
    transform: translate(0, 40px);
    display: none;
}

.tryAgainButtons {
    position: absolute;
    bottom: -55px;
    right: -20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    column-gap: 7px;
}

.tryAgainButton {
    min-width: 155px;
}

.tryAgainButton .buttonSvg {
    height: 53px;
    width: 155px;
}

.tryAgainButton .buttonSvg svg {
    height: 53px;
    width: auto;
}


.tryAgainButton .outlineText {
    font-size: 20px;
    color: rgb(0, 80, 120);
    transform: translate(0, 5px);
}

.tryAgainButton .outlineOuter {
    -webkit-text-stroke: 6px white;

}

.category-button-selected .category-button-text {
    color: rgb(18, 163, 29)
}

.category-button-unselected {
    filter: none;
    transform: scale(0.9);
    transform-origin: center center;
}

.category-button-display-container-unselected {
    filter: none;
}

.innerText {
    display: flex;
    justify-content: center;
    align-items: center;

}

.llmaoText {
    color: rgb(0, 113, 188);
    filter: drop-shadow(4px 4px 0px rgba(0,0,0, 1));
    justify-content: flex-start;
    margin-bottom: 10px;
}

.jokeTopicText {
    font-size: 28px;
}

.jokeTopicText-outer {

}

.buttonSvg {
    height: 55px;
    width: 55px;
    cursor: pointer;
}

.buttonSvg div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonSvg svg {
    height: 55px;
    width: 55px;
}


.sendButton {
    position: absolute;
    right: 0;
    bottom: -15px;;
}

.likeNumber svg {
    height: 50px;
    width: auto;
}

.likeNumber {
    height: 50px;
    min-width: 75px;
    padding-left: 20px;
}

.liked .like-cls-1 {
    fill: #720c13 !important;
}

.notLiked .like-cls-1 {
    fill: #999999 !important;
}


.likesContainer {
    position: relative;
    margin-right: 10px;
}

.likeCount {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    color: white;
}

.joke-item-topic-last {
    transform: translate(15px, 20px);
}

.jokeSplashContainer {
    position: absolute;
}

.joke-viewport {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.searchButton {
    margin-left: 10px;
}


.llmaoAppDesktop .searchButton {
    right: 10px;
    bottom: 5px;
}

.searchButton.searching {
}

.searchButton.searching .search-cls-2 {
    fill: #720c13;
}

.searchCross .uuid-b038f519-6033-4177-a427-028ad40e9a71 {
    fill: #004d77;
}


.uiChatMarginBottom {
    display: flex;
    margin-top: 0px;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(100% - 15px);
    position: relative;
}

.uiChatMessages {
    padding-top: 0px;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: 0px;
    padding-right: 25px;
}

.uiChatMessagesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 25px;
    padding-bottom: 55px;
}

.uiChatMessagesFooter {
    height: 10px;
    width: 100%;
}

.topLaughs {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
}


.fade {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.fade-in {
  opacity: 1;
}

.sendButtonBuy {
    width: 150px;
}
.sendButtonBuyContainer {
    transform: translate(10px, 0);
}

.llmaoAppMobile .sendButtonBuy {
}

.sendButtonBuy .buttonSvg {
    width: 100%;
}

.sendButtonBuy .buttonSvg svg {
    width: 100%;
}

.sendButtonContainer {
    position: relative;
    display: flex;
    transition: opacity 0.33s ease-in-out;
    opacity: 0;
    padding-right: 10px;
    padding-bottom: 5px;
}

.sendButtonContainer .likesContainer {
    transform: translate(20px, 0px);
}

.sendButtonContainer .likeCount {
    display: flex;
    transform: translate(7.5px, 0);
}

.llmaoAppMobile .sendButtonContainer {
    transform: translate(10px, 0);
}


.searchFieldDiv {
    width: 100%;
}

.llmaoAppDesktop .searchFieldDiv {
    margin-top: 0px;
}

.topLaughsDown {
    padding-left: 25px;
    padding-right: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    background: orange;
    overflow-y: auto;
    box-sizing: border-box;
}

.joke-topic .inputControlInputEditorInput {
    width: calc(100% - 155px);
}

.cross-transform {
    transform: rotateZ(-9deg);
    transform-origin: center center;
}

.shared .uuid-8e97b76f-658f-46e1-98ec-78859a34afdf {
    fill: rgb(16, 163, 39);
}

.clickAwayFillerContainer {
    width: 100%;
}

.clickAwayFiller {
    position: absolute;
    min-height: 100%;
    width: 100%;
    left: -15px;
    opacity: 0.5;
    overflow: visible;
}

.jokeDate {
    color: white;
    padding: 10px;
}

.llmaoAppMobile .yourJokesDate {
    margin-top: 25px;
}

.gpt-chat {
    position: absolute;
    width: 100%;
    position: relative;
    height: calc(100vh - 200px);
}

.gpt-chat-input {
    position: absolute;
    bottom: -30px;
    width: 100%;
    display: none;
}

.gpt-chat-input .joke-topic {
    background: rgb(0, 77, 119);
}

.gpt-chat-input .jokeTopicLabel .outlineInner {
    color: rgb(0, 77, 119);
}

.gpt-chat .uiChatMarginBottom {
    height: calc(100% - 35px);
}

.trapezoid.orange.withMarkdown {
    padding-top: 25px;
    padding-bottom: 40px;
    transform: translate(10px, 0);
}
.trapezoid.orange.withMarkdown .markdownContainer {
    width: calc(100% - 0px);
}
