iframe#webpack-dev-server-client-overlay{display:none!important}

.joke-app-container-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}


.llmaoAppDesktop .joke-app-container-container {
    height: 100%;
    width: 100%;
    left: 0;
}


.joke-app-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.joke-app {
    font-family: Arial, sans-serif;
    margin-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    margin-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.joke-app-padding {
    height: 100%;
    width: 100%;
}

.jokeSplashContainer {
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}


.jokeSplashContainer-lighten {
    background: #D3AC09;
    mix-blend-mode: lighten;
}

.joke-app-top-right {
    position: absolute;
    top: 0;
    width: 100%;
}

.joke-app-bottom-left {
    position: absolute;
    bottom: 0;
    width: 100%;

}

.llmaoAppDesktop .joke-app-top-right {
  max-width: calc(min(600px, 100vw));
}

.llmaoAppDesktop .joke-app-bottom-left {
  max-width: calc(min(600px, 100vw));
}

.poweredBy {
    position: absolute;
    left: 0px;
    top: 80vh;
    display: flex;
    transform: translateY(-50%) rotate(-90deg);
    transform-origin: left top;    
               
}

.poweredByLeft {
    color: white;
}

.poweredByRight {
    color: purple;
}

.llmaoAppDesktop .poweredBy {
    left: calc((100vw - 600px) / 2)
}




