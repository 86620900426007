.bnPaymentMethodInput {
    font-size: 16px;
    color: #e7e7e7;
    width: 100%;
    background: rgb(201, 75, 36);
    padding: 10px;
    margin-top: 6px;
    box-sizing: border-box;
    margin-top: 0px;
    margin-bottom: 0px;
    clip-path: polygon(0px 0px, calc(100% - 0px) 0%, calc(100% - 10px) 100%, 7px 100%);
    padding: 25px;
    box-sizing: border-box;
    margin-top: -15px;
    margin-bottom: -10px;
}

.fllmaoAppMobileWeb .bnPaymentMethodInput {
    transform: scale(.9, .9);
    margin-top: -30px;
    margin-bottom: -30px;
}

.bnPaymentMethodInputContainer {
    position: relative;
}

.bnPaymentMethodInput html {
    color: white !important;
}


