.bnClient {
    height: 100%;
    width: 100%;
    background: rgb(211, 171, 11);
    position: fixed;
}

.benxtAppWindows {
    font-family: arial !important;
}

.benxtAppWindows::scrollbar {
    display: none;
}
