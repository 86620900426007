.app {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center
}

.appContent {
    height: 100%;
    width: 100%;
    overflow:hidden;
}

.llmaoAppDesktop .appContent {
    width: 600px;
}

.splashImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.splashImgContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
