.haLLMAO {
    width: 230px;
    margin-bottom: 25px;
    position: absolute;
    left: 15px;
    top: calc(15px + env(safe-area-inset-top));
}

.llmaoAppDesktop .haLLMAO  {
    left: calc((100vw - 600px) / 2 + 15px);
}

.haLLMAO div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.haLLMAO svg {
    height: 100%;
    width: 100%;
}

.llmaoUI {
    display: flex;
    height: 100%;
}

.llmaoUIContainer {
    height: 100%;
    width: 100%;
}

.llmaoUIPage {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
}

.llmaoUIPageContentPadding {
    padding: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
}

.llmaoSignUp {
    width: calc(100% - 30px);
    height: calc(100% + 15px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
}

.llmaoSignUpHeader {
    position: relative;
    margin-top: 50px;
    width: 100%;
    height: 120px;
    filter: drop-shadow(4px 4px 0px #202d44);
    margin-bottom: 15px;
}

.llmaoSignUpBody {
    width: 100%;
    height: calc(100% - 75px);
    padding-bottom: 180px;
}

.llmaoSignUpHeader div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.llmaoSignUpHeader svg {
    width: 100%;
    height: 100%;
}

.llmaoHeader {
    width: 100%;
    height: 75px;
}

.llmaoSignUpButtons {
    margin-top: 40px;
    width: 100%;
    justify-content: center;
    display: flex;
}

.llmaoSignUpButtonsLeft {
    justify-content: flex-start;
}

.llmaoSignUpError div {
    height: 100%;
}

.llmaoSignUpError svg {
    height: 100%;
}

.llmaoSignUpError {
    margin-top: 40px;
    background: rgb(147, 39, 143);
    width: calc(100% - 40px);
    display: flex;
    height: 30px;
    padding: 20px;
    color: white;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.button svg {
    width: 150px;
}

.button {
    width: 150px;
}

.button div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button svg {
    height: 100%;
    width: 100%;
}

.appPlaceholder {
    height: 100%;
    width: 100%;
}

.bnInputField {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
}

.bnInputFieldPhoneInput {
    outline: none;
    border: none;
    padding-left: 15px;
    font-size: 16px;
    width: calc(100% - 15px);
}

.bnInputFieldPhoneInputField {
    display: flex;
    position: relative;
    width: 100%;
}

.bnInputFieldPhoneInputCountryCode {
    height: 40px;
    width: 100px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: rgb(255, 242, 159);
    padding-left: 10px;
    border-right: 1px solid  rgb(0, 26, 25);
}

.bnInputFieldPhoneInputCountryCodeInput {
    z-index: 5;
    display: flex;

}

.bnInputFieldFieldPhone {
    overflow: visible;
}

.bnInputFieldFieldPhone .uiSelectionList {
}

.bnInputFieldPhoneInput input {
    font-size: 16px;
    background: transparent;
    border: 0;
    outline: none;
    padding: 0;
    padding-left: 15px;
    width: calc(100% - 0px);
    color: #333333;
    outline: none;
    border: none;
    padding-left: 0px;
    font-size: 16px;
    width: calc(100% - 15px);
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.uiSelectionList {
    display: flex;
    transform: translate(0, -15px);
    flex-direction: column;
    min-width: calc(100%);
    max-height: calc(100vh - 360px);
    justify-content: flex-start;
    background: #D3AC09;
    padding-top: 1px;
    row-gap: 1px;
    z-index: 10;
    overflow-y: auto;
}

.llmaoAppDesktop .uiSelectionList {
    max-height: calc(100vh - 311px);
}

.uiSelectionListOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    height: 40px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    background: rgb(255, 242, 159);
    border: 0;
    color: #333333;
    font-weight: normal;
}

@media (hover: hover) {
.uiSelectionListOption:hover {
    background:  rgb(255, 242, 159);
    color: #666666;
}
}

.uiSelectionListOptionName {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    max-width: 100%;
    text-overflow: wrap;
    overflow: hidden;
}

.uiSelectionListOptionRight {
    display: flex;
    align-items: center;
}

.uiSelectionListOptionCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.uiSelectionListOptionCheck div  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.uiSelectionListOptionCheck svg {
    height: 20px;
    width: 20px;
    fill: #333333 !important;
}

.uiSelectionListOptionCheck svg path {
    fill: #333333 !important;
}

.llmaoPhoneInput {
    z-index: 5;
    min-height: 75px;
    max-width: 100%;
}

.llmaoAppDesktop .llmaoSignUpHeader {
    margin-bottom: 30px;
}

.llmaoAppDesktop .llmaoPurchase .llmaoSignUpHeader {
    margin-top: 65px;
    margin-bottom: 0px;
}

.llmaoPurchase .llmaoSignUpHeader {
    margin-bottom: 0px;
}

.outlineText.purchaseTitleText {
    font-size: 24px;
}

.llmaoAppMobile .outlineText.purchaseTitleText {
    font-size: 18px;
}


.purchaseTitleText .outlineOuter {
    -webkit-text-stroke: 7px white;
}

.purchaseTitleText .outlineInner {
    color: rgb(114, 12, 19);
}


.currentJokeCount {
    position: absolute;
    right: 0;
    width: 100%;
    top: -15%;
}

.llmaoAppDesktop .currentJokeCount {
    top: -20%;
}

.currentJokeCount .outlineText {
    transform: translate(27.5%, 0);
}

.llmaoAppDesktop .currentJokeCount .outlineText {
    font-size: 38px;
    transform: translate(22.5%, 0);
}

.llmaoAppMobile .outlineText.jokeCountText {
    font-size: 30px;
}

.jokeCountText .outlineOuter {
    -webkit-text-stroke: 6px rgb(242, 242, 242);
}

.jokeCountText .outlineInner {
    color: rgb(201, 75, 36);
}

.jokePurchaseTitle {
    width: 100%;
    display: flex;
}


.jokePackPurchasePage {
    width: 100%;
}

.jokePackPurchasePagePurchase .jokePackInvoiceBody {
    margin-top: -7px;
}

.jokePackPurchasePagePurchase .jokePackContainer {
    transform: translate(5px, 7px);
    width: calc(100% - 10px);
    filter: none;
}

.jokePackInvoiceContainer {
    position: relative;
    margin-bottom: 30px;
}

.jokePackInvoiceBody {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    filter: drop-shadow(4px 4px 0px #202d44);
}

.jokePackInvoice {
    background: white;
    display: flex;
    padding: 25px;
    padding-top: 15px;
    flex-direction: column;
    row-gap: 2px;
    font-size: 20px;
    color: rgb(160, 160, 160);
    font-weight: bold;
}

.llmaoAppNative .jokePackInvoice {
    padding: 30px;
}

.jokePackInvoiceLineItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.jokePackInvoiceLineItemPrice {
}

.jokePackLineItemTotal {
    color: rgb(250, 90, 36);
    font-weight: bold;
}

.jokePackBuyButton {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    bottom: -50px;
}

.jokePackBuyButton .plain-button-display-container {
    height: 70px;
    width: 150px;
    filter: none;
}

.jokePackBuyButton .buttonSvg  {
    height: 70px;
    width: 150px;
}

.jokePackBuyButton .buttonSvg svg {
    height: 70px;
    width: auto;
}

.llmaoPurchase {
    width: calc(100% - 30px);
    height: calc(100% + 15px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
}

.bodyStripe {
    margin-top: calc(env(safe-area-inset-top) + 70px)
}

.bodyStripeError {
    padding-bottom: 300px;
}

.llmaoAppMobileWeb {
}

.purchaseError {
    width: 100%;
    margin-top: -30px;
    margin-bottom: -5px;
}

.llmaoAccount {
    height: 100%;
    width: 100%;
    margin-top: 100px;
}

.llmaoAccountCloseContainer {
    margin-top: 100px;
}

.llmaoAccountCloseButtonTopRow {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 20px;
    column-gap: 0px;
    height: 45px;

}

.llmaoAccountCloseButton {
    margin-top: 5px;
    background: rgb(114, 12, 19);
    color: white;
    padding: 15px;
}

.llmaoAccountCloseCross svg {
    height: 45px;
}

.llmaoAccountCloseCross .crossOutline {
    display: none;
}

.llmaoAccount .joke-topic {
    background: rgb(102, 102, 102);
}

.llmaoAccount .jokeTopicLabel .outlineInner {
    color: rgb(102, 102, 102);
}

.buttonSignOut  {
    width: 180px;
}

.llmaoAccountCloseText {
    display: flex;
    width: 100%;
}

.llmaoAccountCloseText .outlineText.purchaseTitleText {
    font-size: 20px;
}

.llmaoAccountCloseText .outlineText.purchaseTitleText {
    font-size: 20px;
}

.llmaoAppMobile .llmaoAccountCloseText .outlineText.purchaseTitleText {
    font-size: 16px;
}


.llmaoCloseButtonColumns {
    display: flex;
    align-items: flex-start;
    background: rgb(114, 12, 19);
    color: white;
    transform: scale(0.98, 0.92);
    margin-top: 5px;
    clip-path: polygon(0 0, 100% 3px, calc(100% - 5px) 100%, 3px 100%);
}

.llmaoCloseButtonOuter {
    background: white;
    clip-path: polygon(0 0, 100% 3px, calc(100% - 5px) 100%, 3px 100%);
}

.llmaoCloseButtonOutline {
    filter: drop-shadow(4px 4px 0px #202d44);
}

.llmaoAccountCloseButtonBottomRow {
    font-style: italic;
    padding: 10px;
    padding-left: 0px;
    padding-top: 0px;
}


.llmaoCloseButtonConfirm {
    background: rgb(182, 37, 27);
}

.llmaoCloseButtonConfirm  .uuid-80ae087a-bd2d-4937-a377-86a61785e149 {
    fill: rgb(182, 37, 27);
}
