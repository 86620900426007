
.jokePackContainer {
    width: 100%;
    position: relative;
    filter: drop-shadow(4px 4px 0px rgba(0,0,0, 1));
    font-size: 18px;
    cursor: pointer;
}

.jokePackOuter {
   width: 100%;
   height: 100%;
   background: rgb(253, 253, 253);
}

.jokePackLeft {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.jokePackInner {
    transform: scale(.95, .88) translate(-10px, 0);
    width: 100%;
    height: 100%;
    background: rgb(1, 113, 188);
    display: flex;
    justify-content: space-between;
    color: rgb(253, 253, 253);
    align-items: center;
    padding: 10px;
}

.llmaoAppMobile .jokePackInner {
    transform: scale(.92, .88) translate(-10px, 0);
}

.jokePackAmount {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.jokePackAmountType {
    font-size: 20px;
    font-weight: normal;
}

.jokePackFilter {
    flex-grow: 1;
    width: 100%;
}

.jokePackUSD {
    font-weight: bold;
    padding-right: 10px;
    font-size: 20px;
    font-weight: normal;
}

.jokePackIcon {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.jokePackIcon div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.jokePackIcon svg {
    height: 100%;
    width: 100%;
}

.jokePackList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
}

