.llmaoHome {
    height: 100%;
    width: 100%;
}

.llmaoAppDesktop .llmaoHomeContents {
    position: relative;
    max-width: calc(min(600px, 100vw));
}

.userCart {
    display: flex;
    position: absolute;
    top: calc(25px + env(safe-area-inset-top));
    right: 15px;
    cursor: pointer;
    z-index: 10;
}

.llmaoAppDesktop .userCart  {
    right: calc((100vw - 600px) / 2 + 15px);
}



.userCartSignedOut .uuid-c2f913ed-f396-4f3f-a587-9ef648bf9845 {
    fill: rgb(123, 123, 123);
}

#recaptcha-fun {
    visibility: hidden;
}

